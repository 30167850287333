/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                // JavaScript to be fired on all pages

                /* ============================================================
                 *  Global var
                 * ============================================================ */

                var $window = $(window);
                var $document = $(document);
                var $body = $('body');

                /* ============================================================
                 *  Add data-attr to html to render userAgent and platform
                 * ============================================================ */

                var docEl = document.documentElement;

                docEl.setAttribute('data-useragent', navigator.userAgent);
                docEl.setAttribute('data-platform', navigator.platform );

                /* ============================================================
                 *  Namespace
                 * ============================================================ */

                var onScroll = onScroll || {};
                var checkForClass = checkForClass || {};

                /* ============================================================
                 *  Sticky sidebar
                 * ============================================================ */

                $(".sidebar--content_wrapper").stick_in_parent({
                    parent: ".surface",
                    offset_top: $body.hasClass('admin-bar') ? 132 : 100,
                    inner_scrolling: true
                });

                /* ============================================================
                 *  Check for class .has_noThumbnail and add class .is_onContent
                 * ============================================================ */

                checkForClass.setNavClasses = function () {
                    var $navbar = $('.nav--primary');

                    if ( $body.hasClass("add-post") || $body.hasClass("edit-post") ) {
                        $navbar.addClass("is_fixed");
                        $navbar.addClass("is_inViewport");
                        $navbar.addClass("is_onContent");
                        console.log('yo');
                    }

                    if ( $(".has_noThumbnail")[0] ) {
                        $navbar.addClass("is_onContent");
                    } else {
                        return false;
                    }

                };

                checkForClass.setNavClasses();

                /* ============================================================
                 *  Change load more btn content when class "loading" is active
                 * ============================================================ */

                  $.fn.almComplete = function(alm){
                    $('#load-more').html('<img src="http://'+ document.location.hostname +'/wp-content/themes/sept-club/dist/images/animated_ellipsis.svg">');
                  };

                  $.fn.almDone = function(){
                    $('#load-more').html('Il n\'y a plus d\'article à afficher!');
                  };

                /* ============================================================
                 *  Scroll triggered
                 * ============================================================ */

                onScroll.triggerIsScrolled = function () {

                    var $navbar = $('.nav--primary'),
                        $header = $('.page--header'),
                        prevScroll = 0,
                        headerHeight = $($header).outerHeight(),
                        navHeight = $($navbar).outerHeight(),
                        scrollLimit = headerHeight - navHeight;

                    $window.scroll(function () {

                        var scroll = $window.scrollTop();

                        if ( $body.hasClass( 'add-post' ) || $body.hasClass( 'edit-post' ) ){

                          return false;

                        } else {

                            if (scroll >= navHeight) {

                                $navbar.addClass("is_fixed");

                                if (!$navbar.hasClass("is_inViewport")) {
                                    $navbar.addClass("is_hidden");
                                }

                                if (scroll >= scrollLimit) {
                                    if (scroll <= prevScroll) {
                                        $navbar.removeClass("is_hidden");
                                        $navbar.addClass("is_inViewport");
                                        $navbar.addClass("is_onContent");
                                    } else {
                                        $navbar.removeClass("is_inViewport", function(){
                                            $navbar.addClass("is_hidden", function(){
                                                $navbar.removeClass("is_onContent");
                                            });
                                        });
                                    }
                                } else {
                                    if ( !$header.hasClass("has_noThumbnail") && !$header.hasClass("is_light") ) {
                                        $navbar.removeClass("is_onContent");
                                    }
                                }
                            } else {
                                $navbar.removeClass("is_hidden");
                                $navbar.removeClass("is_fixed");
                                $navbar.removeClass("is_inViewport");
                            }

                            prevScroll = scroll;

                        }

                    });

                };

                onScroll.triggerIsScrolled();

                /* ============================================================
                 *  Toggle modal
                 * ============================================================ */

                var infoModal = $('#modal');

                // Get content via ajax
                // PHP function in lib/nav.php

                var get_ajax_dialog = function($source, $type) {

                    $.ajax({
                        url: js.ajaxurl,
                        type: "GET",
                        dataType: 'html',
                        data: {
                            action: 'open_ajax_dialog',
                            type: $type
                        },
                        success: function(response){
                            var modalData = response;
                            infoModal.find('.modal-content')[0].innerHTML = modalData;
                            infoModal.modal();
                            triggerFloatingLabel();
                            formVal($type);
                        },
                        error: function (xhr, status, error) {
                           if (xhr.status > 0) {
                               console.log('got error: ' + status);
                           }
                       }
                    });
                };

                $document.on('click', '.modal-toggle', function(e){
                    e.preventDefault();

                    var $source = this,
                        $type = $source.getAttribute('data-target');

                    get_ajax_dialog($source, $type);
                });

                /* ============================================================
                 *  Bootstrap modal fix for iOS Safari Mobile user
                 *  Fix via: http://bit.ly/1NrPLy0
                 * ============================================================ */

                // iOS check...ugly but necessary
                if( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
                    $('.modal').on('show.bs.modal', function() {
                        // Position modal absolute and bump it down to the scrollPosition
                        $(this)
                            .css({
                                position: 'absolute',
                                marginTop: $(window).scrollTop() + 'px',
                                bottom: 'auto'
                            });
                        // Position backdrop absolute and make it span the entire page
                        //
                        // Also dirty, but we need to tap into the backdrop after Boostrap
                        // positions it but before transitions finish.
                        //
                        setTimeout( function() {
                            $('.modal-backdrop').css({
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: Math.max(
                                    document.body.scrollHeight, document.documentElement.scrollHeight,
                                    document.body.offsetHeight, document.documentElement.offsetHeight,
                                    document.body.clientHeight, document.documentElement.clientHeight
                                ) + 'px'
                            });
                        }, 0);
                    });
                }


                /* ============================================================
                 *  Trigger label when typing in input starts
                 * ============================================================ */

                var triggerFloatingLabel,
                    dialogInput = '.form--input',
                    label = '.form--label',
                    floatingLabel = '.form--label_floating';

                triggerFloatingLabel = function () {
                    $(dialogInput).each(function () {
                        val = $(this).val();

                        if (val) {
                            $(this).parent(label).find(floatingLabel).addClass('is_floating');
                        } else {
                            $(this).parent(label).find(floatingLabel).removeClass('is_floating');
                        }
                    });
                };

                $document.on('keyup', dialogInput, function () {
                    var valueLength = this.value.length;

                    if (valueLength >= 1) {
                        $(this).parent(label).find(floatingLabel).addClass('is_floating');
                    } else {
                        $(this).parent(label).find(floatingLabel).removeClass('is_floating');
                    }
                });

                /* ============================================================
                 *  Instant form validation using: http://formvalidation.io
                 * ============================================================ */

                var formVal = function ($type) {
                    if ($type === "login") {
                        $('#' + $type).formValidation({
                            framework: 'bootstrap',
                            icon: {
                                valid: 'fa fa-check',
                                invalid: 'fa fa-times',
                                validating: 'fa fa-refresh'
                            },
                            fields: {
                                user_login: {
                                    icon: 'false',
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir votre identifiant.'
                                        },
                                        blank: {}
                                    }
                                },
                                user_pass: {
                                    icon: 'false',
                                    validators: {
                                        notEmpty: {
                                            message: '<a class="link--form_error l-switch modal-toggle" data-toggle="modal" data-target="lostpwd" tabindex="-1" href="javascript:void(0)">Avez-vous oublié votre mot de passe?</a>'
                                        },
                                        blank: {}
                                    }
                                }
                            }
                        })
                            .on('success.form.fv', function (e) {
                              // Prevent default form submission
                              e.preventDefault();

                              $.ajax({
                                  type: 'POST',
                                  dataType: 'json',
                                  url: js.ajaxurl,
                                  data: {
                                    'action': 'login',
                                    'username': $('#user_login').val(),
                                    'password': $('#user_pass').val(),
                                    'security': $('#login_nonce').val()
                                  },
                                  success: function( data ) {
                                    if ( data.loggedIn === true ) {
                                      $('.dialog--content[data-target="login"]').html('<div class="display--status" data-status="success"><i class="fa fa-check fa-5x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                                      setTimeout(function(){
                                        document.location.href = 'http://'+ document.location.hostname;
                                      }, 1000);
                                    } else {
                                        $('.display--status').remove();
                                        $('.form').prepend('<div class="display--status" data-status="error"><i class="fa fa-times fa-2x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                                    }
                                  }
                              });
                            }).on('err.form.fv', function () {
                                $('.display--status').remove();
                                $('.form').prepend('<div class="display--status" data-status="error"><i class="fa fa-times fa-2x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                            });
                    } else if ($type === "signup") {
                        $('#' + $type).formValidation({
                            framework: 'bootstrap',
                            icon: {
                                valid: 'fa fa-check',
                                invalid: 'fa fa-times',
                                validating: 'fa fa-refresh'
                            },
                            fields: {
                                user_first: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir votre prénom'
                                        },
                                        blank: {}
                                    }
                                },
                                user_last: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir votre nom de famille'
                                        }
                                    }
                                },
                                user_login: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir un identifiant'
                                        },
                                        stringLength: {
                                            min: 6,
                                            max: 30,
                                            message: 'Votre identifiant doit contenir entre 6 et 30 caractères'
                                        },
                                        regexp: {
                                            regexp: /^[a-zA-Z0-9_\.]+$/,
                                            message: 'Votre identifiant ne peux contenir que des caractères alphabétique, numéricale, des points ou des traits de soulignements'
                                        },
                                        blank: {}
                                    }
                                },
                                user_email: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir une adresse e-mail'
                                        },
                                        emailAddress: {
                                            message: 'Veuillez saisir une adresse e-mail valide'
                                        },
                                        blank: {}
                                    }
                                },
                                user_pass: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir un mot de passe'
                                        },
                                        stringLength: {
                                            min: 6,
                                            max: 30,
                                            message: 'Votre mot de passe doit contenir entre 6 et 30 caractères'
                                        },
                                        regexp: {
                                            regexp: /^[a-zA-Z0-9_\.]+$/,
                                            message: 'Votre mot de passe ne doit contenir que des caractères alphabétique, numéricale, des points ou des traits de soulignements'
                                        },
                                        different: {
                                            field: 'user_login',
                                            message: 'Votre mot de passe doit être différent de votre identifiant'
                                        },
                                        blank: {}
                                    }
                                },
                                user_pass_confirm: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez confirmer votre mot de passe'
                                        },
                                        stringLength: {
                                            min: 6,
                                            max: 30,
                                            message: 'Votre mot de passe doit contenir entre 6 et 30 caractères'
                                        },
                                        regexp: {
                                            regexp: /^[a-zA-Z0-9_\.]+$/,
                                            message: 'Votre mot de passe ne doit contenir que des caractères alphabétique, numéricale, des points ou des traits de soulignements'
                                        },
                                        identical: {
                                            field: 'user_pass',
                                            message: 'Le mot de passe ne correspond pas'
                                        }
                                    }
                                }
                            }
                        }).on('success.form.fv', function (e) {
                            e.preventDefault();
                            $.ajax({
                                type: 'POST',
                                dataType: 'json',
                                url: js.ajaxurl,
                                data: {
                                    'action': 'register',
                                    'user_first_name': $('#user_first').val(),
                                    'user_last_name': $('#user_last').val(),
                                    'user_login': $('#user_login').val(),
                                    'user_email': $('#user_email').val(),
                                    'user_password': $('#password').val(),
                                    'user_pass_confirm': $('#password_again').val(),
                                    'security': $('#register_nonce').val()
                                },
                                success: function ( data ) {
                                    $('.display--status').remove();
                                    if ( data.error === false ) {
                                        $('.dialog--content[data-target="signup"]').html('<div class="display--status" data-status="success"><i class="fa fa-check fa-5x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                                        setTimeout(function(){
                                          document.location.href = 'http://'+ document.location.hostname +'/author/'+ data.login +'';
                                        }, 1000);
                                    }
                                    else {
                                        $('.display--status').remove();
                                        $('.form').prepend('<div class="display--status" data-status="error"><i class="fa fa-times fa-2x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                                    }
                                }
                            });
                        }).on('err.form.fv', function () {
                            $('.display--status').remove();
                            $('.form').prepend('<div class="display--status" data-status="error"><i class="fa fa-times fa-2x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                        });
                    } else if ($type === "edit") {
                        $('#' + $type).formValidation({
                            framework: 'bootstrap',
                            icon: {
                                valid: 'fa fa-check',
                                invalid: 'fa fa-times',
                                validating: 'fa fa-refresh'
                            },
                            fields: {
                                user_first: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir votre prénom'
                                        },
                                        blank: {}
                                    }
                                },
                                user_last: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir votre nom de famille'
                                        },
                                        blank: {}
                                    }
                                },
                                user_mail: {
                                    validators: {
                                        emailAddress: {
                                            message: 'Veuillez saisir une adresse e-mail valide'
                                        },
                                        blank: {}
                                    }
                                },
                                password: {
                                    validators: {
                                        stringLength: {
                                            min: 6,
                                            max: 30,
                                            message: 'Votre mot de passe doit contenir entre 6 et 30 caractères'
                                        },
                                        regexp: {
                                            regexp: /^[a-zA-Z0-9_\.]+$/,
                                            message: 'Votre mot de passe ne doit contenir que des caractères alphabétique, numéricale, des points ou des traits de soulignements'
                                        },
                                        blank: {}
                                    }
                                },
                                password_again: {
                                    validators: {
                                        identical: {
                                            field: 'password',
                                            message: 'Le mot de passe ne correspond pas'
                                        },
                                        stringLength: {
                                            min: 6,
                                            max: 30,
                                            message: 'Votre mot de passe doit contenir entre 6 et 30 caractères'
                                        },
                                        regexp: {
                                            regexp: /^[a-zA-Z0-9_\.]+$/,
                                            message: 'Votre mot de passe ne doit contenir que des caractères alphabétique, numéricale, des points ou des traits de soulignements'
                                        }
                                    }
                                }
                            }
                        }).on('success.form.fv', function (e) {
                            e.preventDefault();
                            $.ajax({
                                type: 'POST',
                                url: js.ajaxurl,
                                dataType: 'json',
                                data: {
                                    'action': 'update_user_profile',
                                    'user_first': $('#user_first').val(),
                                    'user_last': $('#user_last').val(),
                                    'user_mail': $('#user_mail').val(),
                                    'password': $('#password').val(),
                                    'password_again': $('#password_again').val(),
                                    'security': $('#update_user_nonce').val()
                                },
                                success: function( data ) {
                                    $('.display--status').remove();
                                    if ( data.error === false ) {
                                        $('.dialog--content[data-target="edit"]').html('<div class="display--status" data-status="success"><i class="fa fa-check fa-5x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                                        setTimeout(function(){
                                          document.location.href = 'http://'+ document.location.hostname;
                                        }, 1000);
                                    }
                                    else {
                                        $('.display--status').remove();
                                        $('.form').prepend('<div class="display--status" data-status="error"><i class="fa fa-times fa-2x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                                    }
                                }
                            });
                        }).on('err.form.fv', function () {
                            $('.display--status').remove();
                            $('.form').prepend('<div class="display--status" data-status="error"><i class="fa fa-times fa-2x"></i><p class="display--status_message">'+ data.message +'</p></div>');
                        });
                    } else if ($type === "lostpwd") {
                        $('#' + $type).formValidation({
                            framework: 'bootstrap',
                            icon: {
                                valid: 'fa fa-check',
                                invalid: 'fa fa-times',
                                validating: 'fa fa-refresh'
                            },
                            fields: {
                                username: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Veuillez saisir votre identifiant ou votre adresse e-mail'
                                        }
                                    }
                                }
                            }
                        }).on('success.form.fv', function () {
                            $('.dialog--content[data-target="lostpwd"]').html('<div class="display--status" data-status="success"><i class="fa fa-check fa-5x"></i></div>');

                        }).on('err.form.fv', function () {
                            $('.dialog--content[data-target="lostpwd"]').html('<div class="display--status" data-status="error"><i class="fa fa-times fa-5x"></i></div>');
                        });
                    }
                };

                /* ============================================================
                 *  Replace all SVG images with inline SVG
                 * ============================================================ */

                jQuery('img.svg').each(function () {
                    var $img = jQuery(this);
                    var imgID = $img.attr('id');
                    var imgClass = $img.attr('class');
                    var imgURL = $img.attr('src');

                    jQuery.get(imgURL, function (data) {
                        // Get the SVG tag, ignore the rest
                        var $svg = jQuery(data).find('svg');

                        // Add replaced image's ID to the new SVG
                        if (typeof imgID !== 'undefined') {
                            $svg = $svg.attr('id', imgID);
                        }
                        // Add replaced image's classes to the new SVG
                        if (typeof imgClass !== 'undefined') {
                            $svg = $svg.attr('class', imgClass + ' replaced-svg');
                        }

                        // Remove any invalid XML tags as per http://validator.w3.org
                        $svg = $svg.removeAttr('xmlns:a');

                        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                        }

                        // Replace image with new SVG
                        $img.replaceWith($svg);

                    }, 'xml');

                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                /* ============================================================
                 *  Load Sept.info JSON to display on autoAD
                 * ============================================================ */

                var url = 'https://www.sept.info/wp-json/posts?filter%5Bignore_sticky_posts%5D=1';

                $.ajax({
                    type: 'GET',
                    url: url,
                    async: true,
                    dataType: 'json',
                    success: function(json) {
                        $('#ad_link_primary, #ad_link_secondary').attr('href', json[0].link);
                        $('#ad_title').html(json[0].title);
                        $('#ad_author').html(json[0].author.name);
                        $('#ad_featured_img').attr('src', json[0].featured_image);
                    },
                    error: function(e) {
                        console.log(e.message);
                    }
                });

            }
        },

        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'single': {
          init: function(){


            /* ============================================================
             *  Add class to article parts
             * ============================================================ */

            addClassToArticle = function(){

                var $entryContent = $('.entry--content');

                $entryContent.find('h1, h2, h3, h4, h5, h6').addClass('layoutCentered--constrain');
                $entryContent.find('p').not(":has(img), blockquote p").addClass('ent--p layoutCentered--constrain');
                $entryContent.find('p').first().not(":has(img), blockquote p").addClass('has_dropCap');
                $entryContent.find('p').has('img').addClass('ent--img');
                $entryContent.find('a').addClass('ent--link');
                $entryContent.find('blockquote').addClass('ent--quote');

            };

            addClassToArticle();

            /* ============================================================
             *  Get image Lightness to change navbar state
             * ============================================================ */

             var getImageLightness = function(imageSrc,callback) {
                 var img = document.createElement("img");
                 img.src = imageSrc;
                 img.style.display = "none";
                 document.body.appendChild(img);

                 var colorSum = 0;

                 img.onload = function() {
                     // create canvas
                     var canvas = document.createElement("canvas");
                     canvas.width = this.width;
                     canvas.height = this.height;

                     var ctx = canvas.getContext("2d");
                     ctx.drawImage(this,0,0);

                     var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
                     var data = imageData.data;
                     var r,g,b,avg;

                     for(var x = 0, len = data.length; x < len; x+=4) {
                         r = data[x];
                         g = data[x+1];
                         b = data[x+2];

                         avg = Math.floor((r+g+b)/3);
                         colorSum += avg;
                     }

                     var brightness = Math.floor(colorSum / (this.width*this.height));
                     callback(brightness);
                 };
             };

             getImageLightness( $('.page--header_img').find('.background--img').attr('src'), function( brightness ) {

                var $navbar = $('.nav--primary');

                 if ( brightness >= 128 ) {
                    $('.page--header').addClass("is_light");
                    $navbar.addClass("is_onContent");
                 } else {
                    return false;
                 }

             });

          }
        },
        // Add Post
        'page': {
            init: function () {
                // JavaScript to be fired on the add post page

                /* ============================================================
                 *  Toggle editor helper
                 * ============================================================ */

                toggleEditorHelper = function(){
                    if($('.editor--helper').attr('data-visible') === "false"){
                        $('.editor--helper').attr('data-visible', 'true');
                    } else {
                        $('.editor--helper').attr('data-visible', 'false');
                    }
                };

                $(document).on('click touchend', '.button[data-trigger="help"], .editor--helper_close', function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    toggleEditorHelper();
                });


            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





